<template>
  <v-sheet color="green" class="fill-height">
    <v-row class="no-gutters fill-height" >
      <v-col v-if="!SM" cols="12" md="6">
        <v-sheet :color="wsLIGHTCARD" class="fill-height d-flex align-center justify-center">
          <v-img
              src="/library/img/home/login.png"
              alt="WeStudy Login"
              max-height="500"
              max-width="500"
          />
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6">
        <v-sheet class="fill-height d-flex align-center justify-center">
          <auth-window-new
              hide-register
              :auth-route="localeLink('dashboard')"
          />
        </v-sheet>
      </v-col>
    </v-row>
  </v-sheet>

</template>

<script>
import authWindowNew from "@/components/auth/authWindowNew";
export default {
  name: "HomepageRegister",
  components : {
    authWindowNew
  },
  beforeMount() {
    this.$emit('hideFooter' , true)
  },
  beforeDestroy() {
    this.$emit('hideFooter' , false)
  }
}
</script>

<style scoped>

</style>